@import '../../../css/colors.css';

.wrapper {
  display: flex;
  justify-content: center;
  padding: 40px;
  width: 720px;
}

.responsiveWidth {
  width: 100vw;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  width: 100%;
  gap: 20px;
}

.container a {
  color: blue;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.logo {
  width: 90px;
  height: 31px;
  position: absolute;
  left: 0;
}

.headingData {
  font-size: 12px;
  font-weight: 600;
  align-self: center;
  text-align: center;
  color: var(--primary);
  font-size: 14px;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.listClass {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.orderedListBlue {
  padding-inline-start: 40;
  gap: 0;
  color: var(--primary);
  font-weight: 600;
  margin-top: 30px;
}

.title {
  font-weight: 600;
  color: var(--primary);
  scroll-margin: 40px;
}

.sectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
}

.table {
  display: flex;
  border: 1px solid var(--gray1);
  flex-direction: column;
}

.tableResponsive {
  flex-direction: column;
}

.tableResponsive .fragment p {
  padding: unset;
}

.row {
  display: flex;
  flex: 1;
  border-bottom: 1px solid var(--gray1);
}

.row:last-child {
  border-bottom: none;
}

.row .meaning {
  padding: 10px;
}

.term {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-right: 1px solid var(--gray1);
}

.term svg,
.fragmentTitle svg {
  width: 50px;
  height: 50px;
}

.termTitle {
  background-color: var(--primary);
  color: var(--white);
}

.rowResponsive {
  display: flex;
  flex-direction: column;
  border-bottom: unset;
  border-bottom: 1px solid var(--gray1);
}

.rowResponsive .term {
  border-right: unset;
}

.meaning {
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.tableHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: var(--primary);
  color: var(--white);
  border-bottom: 2px solid black;
}

.termTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  color: var(--white);
  text-align: center;
}

.fragment {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  border-right: 1px solid var(--gray1);
  flex-direction: column;
  min-height: 50px;
  flex-wrap: wrap;
  text-align: center;
}

.fragment p,
.fragment b {
  padding: 10px;
}

.fragment:last-child {
  border-right: unset;
}

.fragmentTitle {
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  color: var(--white);
}

.tableResponsive .fragmentTitle {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.responsiveDescription {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.fragmentTitleResponsive {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--gray1);
}

.buttonControls {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--elementPadding);
  width: 100%;
  gap: var(--gap10);
}

.buttonControls button {
  width: 50%;
}

.titleSvgContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.titleSvgContainer svg {
  width: 50px;
  height: 50px;
  fill: none;
}

.wrapper ul,
.wrapper ol {
  gap: 0 !important;
  padding-inline-start: 40px;
}
