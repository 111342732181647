@import '../../../css/main.css';
@import '../../../css/colors.css';
@import '../../../css/variables.css';

.buttonWrapper {
  display: flex;
  flex-direction: column;
}

.buttonPrimary,
.buttonPrimaryOutlined,
.buttonSecondary,
.buttonTertiary,
.buttonTertiaryOutlined,
.buttonDanger,
.buttonDangerOutlined,
.buttonSuccess,
.buttonSuccessOutlined,
.buttonGray,
.buttonGrayOutlined,
.buttonUpload {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35rem;
  min-width: fit-content;
  border-radius: 4rem;
  font-size: 13px;
  cursor: pointer;
  user-select: none;
  padding: 0 20rem 0 20rem;
  outline: none;
  border: none;
  box-sizing: border-box;
}

/* upload green */
.buttonUpload {
  border-radius: 50rem;
  background-color: var(--green);
  padding: 5rem 10rem;
  color: var(--white);
  font-weight: 500;
  gap: 5px;
}

/* Primary */
.buttonPrimary {
  color: var(--white);
  background: var(--primaryDark);
}
.buttonPrimary:hover {
  background: var(--primaryHover);
}

.buttonPrimary .countdownAnimation {
  background: var(--logoBlue);
}

/* Primary outlined */
.buttonPrimaryOutlined {
  background-color: inherit;
  color: var(--primary);
  border: 1rem solid var(--primary);
  transition: all 0.2s ease;
}
.buttonPrimaryOutlined:hover {
  color: var(--white);
  background-color: var(--primary);
}

.buttonPrimaryOutlined .countdownAnimation {
  background: var(--primary);
}

/* Secondary */
.buttonSecondary {
  background-color: inherit;
  color: var(--primary);
  border: 1rem solid var(--primary);
  transition: all 0.2s ease;
}
.buttonSecondary:hover {
  color: var(--white);
  background-color: var(--primary);
}

.buttonSecondary .countdownAnimation {
  background: var(--primary);
}

/* Tertiary */
.buttonTertiary {
  color: var(--secondary);
  background: var(--tertiary);
}
.buttonTertiary:hover {
  background: var(--warningHover);
}
.buttonTertiary .countdownAnimation {
  background: var(--warningHover);
}

/* Tertiary outlined */
.buttonTertiaryOutlined {
  background-color: inherit;
  color: var(--warningHover);
  border: 1rem solid var(--tertiary);
  transition: all 0.2s ease;
}
.buttonTertiaryOutlined:hover {
  color: var(--secondary);
  background-color: var(--tertiary);
}
.buttonTertiary .countdownAnimation {
  background: var(--tertiary);
}

/* Button danger */
.buttonDanger {
  background-color: var(--danger);
  color: var(--white);
}
.buttonDanger:hover {
  background-color: var(--dangerHover);
}
.buttonDanger .countdownAnimation {
  background: var(--dangerHover);
}

/* Danger outlined */
.buttonDangerOutlined {
  background-color: inherit;
  color: var(--danger);
  border: 1rem solid var(--danger);
  transition: all 0.2s;
}
.buttonDangerOutlined:hover {
  color: var(--white);
  background-color: var(--danger);
}
.buttonDangerOutlined .countdownAnimation {
  background: var(--danger);
}

/* Success */
.buttonSuccess {
  color: var(--white);
  background-color: var(--success);
}
.buttonSuccess:hover {
  background: var(--successHover);
}
.buttonSuccess .countdownAnimation {
  background: var(--successHover);
}

/* Success outlined */
.buttonSuccessOutlined {
  background-color: inherit;
  color: var(--success);
  border: 1rem solid var(--success);
  transition: all 0.2s;
}
.buttonSuccessOutlined:hover {
  color: var(--white);
  background-color: var(--success);
}
.buttonSuccessOutlined .countdownAnimation {
  background: var(--success);
}

/* Text */
.buttonText {
  color: var(--primary);
  border: none;
  background-color: inherit;
  cursor: pointer;
}

/* Gray */
.buttonGray {
  color: var(--white);
  background-color: var(--gray3);
}
.buttonGray:hover {
  background-color: var(--gray4);
}
.buttonGray .countdownAnimation {
  background: var(--gray4);
}

/* Gray outlined */
.buttonGrayOutlined {
  background-color: inherit;
  color: var(--gray3);
  border: 1rem solid var(--gray3);
  transition: all 0.2s;
}
.buttonGrayOutlined:hover {
  color: var(--white);
  background-color: var(--gray3);
}
.buttonGrayOutlined .countdownAnimation {
  background: var(--gray3);
}

.leftIconWrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 12.5rem;
  width: 15rem;
  height: 15rem;
}

.innerDiv {
  display: flex;
  flex: 1;
  align-content: center;
  justify-content: center;
  font-weight: 400;
}

.disabled {
  color: var(--secondaryText);
  background-color: var(--gray2);
  border: unset;
  cursor: not-allowed;
}

.disabled:hover {
  background-color: var(--gray2);
}

.buttonWithCountdown {
  position: relative;
  display: flex;
  gap: var(--gap5);
  transition: all 3s ease;
  overflow: hidden;
}

.buttonWithCountdownContainer {
  flex: 3;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: var(--gap5);
  z-index: 2;
}

.countdownTime {
  flex: 1;
  text-align: end;
  z-index: 2;
}

.countdownAnimation {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 35px;
  transition: all 2s linear;
  z-index: 1;
}

.clockSvg {
  width: 16px;
  height: 16px;
  fill: white;
}
