.wrapper {
  min-width: var(--elementMinWidth);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: calc(var(--elementPadding) * 2);
  padding: var(--elementPadding);
  background-color: var(--background);
}

.userInfoSticky {
  position: sticky;
  top: var(--formDrawerHeaderHeight);
}

.wrapperMobile {
  flex: none;
  position: unset;
  height: unset;
}

.contentContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap10);
}

.contentWrapper .text {
  text-align: center;
  font-weight: 500;
  color: var(--secondaryText);
}

.contentWrapper .text span {
  color: var(--secondary);
}

.title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: var(--primary);
  text-align: center;
}

.icon {
  width: 95px;
  height: 80px;
}

.hasButtons {
  padding-bottom: 0px;
}
