@import '../../../../css/colors.css';
@import '../../../../css/variables.css';

.inputRow {
  width: 100%;
  display: flex;
  position: relative;
}

.inputDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--white);
  border-radius: var(--borderRadius);
  padding: 0 17rem 0 3rem;
  border: 1rem solid var(--borderFormElement);
  /* border-left: 4rem solid var(--gray3); */
  width: 100%;
  height: 30rem;
  max-height: 30rem;
  transition: border 0.2s;
}

.bigInputDiv {
  height: 35rem !important;
  max-height: 35rem !important;
  font-size: 14rem !important;
}

.inputDiv input {
  height: 100% !important;
  color: var(--secondary);
  line-height: 18px;
  width: 0;
}

.inputDiv ::placeholder {
  color: var(--placeholderColor);
}

.inputDiv:focus-within ::placeholder {
  color: var(--secondaryText);
}

.inputDiv[disabled] {
  background-color: var(--disabledBackgroundColor);
}

.inputDiv[disabled] ::placeholder {
  color: var(--placeholderColor);
}

.inputDiv[disabled]:hover {
  border: 1rem solid var(--borderFormElement);
}

.inputDiv[disabled]:hover {
  border: 1rem solid var(--borderFormElement);
}

.inputDiv:hover {
  border: 1rem solid var(--borderFormElementHover);
}

.inputDiv:focus-within {
  border: 1rem solid var(--borderFormElementFocus);
}

.inputWrapperDiv:focus-within .inputLabel {
  color: var(--colorFormElementFocus);
}

.inputField {
  display: flex;
  flex: 1;
  min-width: 0;
  border: none;
  background-color: transparent;
  padding: 0 5rem 0 5rem;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.inputField:focus {
  outline: none;
}

.inputLabel {
  color: var(--secondaryText);
  font-size: 12px;
  /* line-height: 18px; */
  font-weight: 400;
  margin: 0 0 5px 0;
  user-select: none;
  transition: all 0.2s;
}

.labelWrapper {
  display: flex;
  justify-content: space-between;
}

.inputWrapperDiv {
  display: flex;
  flex-direction: column;
  position: relative;
}

.rightWrapper {
  display: flex;
  flex-direction: column;
  color: var(--placeholderColor);
  transition: color 0.2s;
}

.inputDiv:hover .rightWrapper {
  color: var(--gray4);
}

.inputDiv:focus-within .rightWrapper {
  color: var(--secondary);
}

.inputDiv[disabled] .rightWrapper {
  color: var(--gray2);
}

.leftProp,
.rightProp {
  position: absolute;
  max-width: 24rem;
  max-height: 24rem;
  top: 25rem;
}
.leftProp {
  left: -30rem;
}
.rightProp {
  right: -30rem;
}

.preventDefaultBrowserClass {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
