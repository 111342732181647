.documentList {
  display: flex;
  flex-direction: column;
  gap: var(--gap20);
  color: var(--secondary);
  font-weight: 500;
  line-height: 18px;
  height: fit-content;
  overflow: hidden;
  padding-bottom: 20px;
}

.documents {
  display: flex;
  flex-direction: column;
  gap: var(--gap10);
}

.document {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.document a {
  width: 240px;
  color: var(--primary);
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.document p {
  width: 240px;
  color: var(--primary);
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.document p:hover {
  cursor: pointer;
}

.title {
  font-weight: 400;
  font-size: 14px;
  color: var(--secondary);
}

.title::after {
  content: ':';
}
