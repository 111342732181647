.mainContainer {
  min-width: 740px;
  display: flex;
  flex-direction: column;
  font-size: 7px;
}

.mainContainerResponsive {
  min-width: unset;
  transform: scale(1);
  overflow: scroll;
  max-width: 100vw;
}

.mainContainerResponsiveDocument {
  min-width: unset;
  transform: scale(1);
  overflow: scroll;
  max-width: 100vw;
}

.heading {
  width: 100%;
  padding: 5px 5px 5px 10px;
  background-color: black;
}

.headingInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.number p {
  font-size: 20px;
  color: var(--white);
  font-weight: 500;
}

.cmrType {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.cmrType p {
  font-size: 7px;
  color: var(--white);
}

.formContainer {
  position: relative;
  width: 662px;
  align-self: center;
}

.firstSectionWrapper {
  display: flex;
  flex-direction: row;
  max-height: 380px;
  min-height: 380px;
}

.columnHigh {
  display: flex;
  flex-direction: column;
  max-width: 360px;
  min-width: 360px;
  min-height: 78px;
  max-height: 78px;
}

textarea {
  border: none;
  resize: none;
  outline: none;
  font-size: 10px;
  padding: 5px 0 0 5px;
  background-color: transparent;
  line-height: 1;
  overflow: hidden;
}

textarea:hover {
  cursor: pointer;
}
.inputHigh {
  height: 56px;
  width: calc(100% - 1px);
}

.inputLower {
  height: 52px;
  width: calc(100% - 1px);
}

.inputInstructions {
  height: 134px;
  font-size: 10px;
  width: calc(100% - 1px);
}

.inputSpecial {
  width: calc(100% - 1px);
  height: 55px;
}
.columnShort {
  display: flex;
  flex-direction: column;
  min-height: 78px;
  max-height: 78px;
}

.columnLower {
  display: flex;
  flex-direction: column;
  height: 72px;
}

.columnLowerShort {
  display: flex;
  flex-direction: column;
  height: 72px;
}

.secondSectionWrapper {
  display: flex;
  flex-direction: row;
}

.leftSideContainer {
  display: flex;
  flex-direction: column;
  min-width: 444px;
  max-width: 444px;
}

.topBlock {
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-direction: row;
  max-height: 180px;
  min-height: 180px;
  padding-right: 5px;
}

.bottomBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 88px;
}

.rightSideContainer {
  display: flex;
  flex-direction: row;
}

.row {
  display: flex;
  flex-direction: column;
  width: 72px;
  border-left: 1px solid black;
}

.thirdSectionWrapper {
  display: flex;
  flex-direction: row;
  max-height: 160px;
  min-height: 160px;
}

.sendersInstructions {
  width: 100%;
  border-bottom: 1px solid black;
}

.rightSideTable {
  display: grid;
  grid-template-columns: 82px 72px 72px 72px;
  grid-template-rows: 20px 40px 80px 20px;
}

.gridFragment {
  display: inline-block;
}

.tableColumn {
  display: flex;
  flex-direction: column;
  min-height: 155px;
  max-height: 155px;
  min-width: 70px;
  max-width: 70px;
}

.fourthSectionWrapper {
  display: flex;
  flex-direction: column;
}

.cashOnDelivery {
  border-bottom: 1px solid black;
}

.bottomWrapper {
  display: flex;
  flex-direction: column;
}

.topSection {
  display: flex;
  flex-direction: row;
}

.leftSideWrapper {
  display: flex;
  flex-direction: column;
  min-width: 372px;
  max-width: 372px;
}

.topDiv {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
}

.rightSideWrapper {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
  min-width: 288px;
  max-width: 288px;
}

.bottomSection {
  display: flex;
  flex-direction: row;
  min-height: 86px;
}

.column {
  display: flex;
  flex-direction: row;
  width: 260px;
  padding-left: 5px;
}

.column:last-child {
  border-right: none;
}

.tableContainer {
  display: flex;
}

.footer {
  color: var(--white);
  justify-content: center;
  height: 40px;
  min-width: 100%;
  background-color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.colHeading {
  display: flex;
  max-width: 83px;
  min-width: 83px;
  height: 19px;
  border-bottom: 1px solid black;
}

.col {
  display: flex;
  flex-direction: column;
  max-width: 298px;
}

.reductionsField {
  line-height: 1;
  padding-top: 2px;
  padding-left: 2px;
  display: flex;
  flex-direction: column;
  height: 38px;
  width: 83px;
  border-bottom: 1px solid black;
  gap: 2px;
}

.miscellanous {
  line-height: 1;
  padding-top: 2px;
  padding-left: 2px;
  display: flex;
  flex-direction: column;
  height: 76px;
  border-bottom: 1px solid black;
  width: 83px;
  gap: 2px;
}

.verticalLine {
  display: inline-block;
  border-left: 3px solid black;
  margin: 0 35px;
  height: 138px;
  position: absolute;
}

.sectionInfo {
  display: flex;
  gap: 5px;
  padding-left: 5px;
  font-size: 7px;
}

.smallNumber {
  padding-top: 5px;
  font-weight: 500;
}

.row_1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 7px;
}

.row_2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 7px;
  padding-left: 81px;
}

.additional {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 7px;
}

.bottomDiv {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.helperContainer {
  display: flex;
  flex-direction: column;
  padding-top: 54px;
}

.helperContainerPadding {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 10px;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.helperRow {
  display: flex;
  gap: 80px;
  padding-left: 20px;
}

.helperRowBottom {
  display: flex;
  gap: 74px;
  padding-left: 20px;
}

.gridFieldTwo {
  display: grid;
  grid-template-rows: repeat(2, 20px);
}

.gridFieldThree {
  display: grid;
  grid-template-rows: repeat(3, 20px);
}

.verticalFragment {
  position: relative;
}

.verticalBlock {
  display: flex;
  flex-direction: row;
  gap: 0;
}

.asideContainer {
  margin-top: 100px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  height: 780px;
  width: 40px;
  align-self: center;
}

.asideContainerRight {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 650px;
  width: 40px;
  align-self: center;
}

.formContainerWrapper {
  display: flex;
  flex-direction: row;
  min-height: 932px;
}
.asideLeft_1 {
  transform: rotate(-90deg);
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  line-height: 1.1;
}

.asideLeft_1 p {
  font-size: 7px;
}

.asideLeft_2 {
  transform: rotate(-90deg);
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  line-height: 1.1;
}

.asideLeft_3 {
  transform: rotate(-90deg);
  margin-top: 10px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  line-height: 1.1;
}

.asideRight_1 {
  transform: rotate(90deg);
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  line-height: 1.1;
  justify-content: center;
}

.asideBig {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.asideNumber {
  font-size: 12px;
  font-weight: 500;
}

.logoRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2px;
  padding: 0 10px 5px 10px;
}

.logoColumnContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.logoColumnTitle {
  display: flex;
  flex-direction: column;
}

.logoColumnDescription {
  display: flex;
  flex-direction: column;
  width: 100%;
  white-space: nowrap;
}

.logo {
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding-top: 5px;
}

.logo p {
  font-size: 20px;
  font-weight: 600;
}

.cmrLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  border-radius: 50%;
  width: 40px;
  border: 2px solid black;
}

.logoTextLeft {
  line-height: 1;
  max-width: 144px;
  font-size: 6px;
}

.secondSectionContainer {
  display: flex;
  flex-direction: column;
}

.inputSecondSection {
  width: 84px;
  font-size: 10px;
}

.inputSecondSectionMain {
  width: 84px;
  font-size: 10px;
  position: relative;
}

.textAreaClass {
  max-width: 66px;
  font-size: 8px;
}

.secondSectionInputParent {
  display: flex;
  flex-direction: column;
}

.addButton {
  display: flex;
  position: absolute;
  left: 4px;
  top: 14px;
  width: 14px;
}

.addButton:hover {
  cursor: pointer;
}

.closeButton {
  display: flex;
  position: absolute;
  left: 5px;
  top: 20px;
  width: 10px;
}

.newInputWrapper {
  display: flex;
  position: relative;
}

.parentContainer {
  display: flex;
  position: relative;
}

.circleClose {
  padding: 5px;
  background-color: var(--danger);
  position: absolute;
  border-radius: 50%;
  border-radius: var(--borderRadius);
  cursor: pointer;
  top: 3px;
  width: 14px;
  left: -11px;
  height: 14px;
}

.circleClose::after {
  content: 'X';
  color: var(--white);
  position: absolute;
  top: 2px;
  right: 4px;
  font-size: 8px;
  font-weight: 500;
}

.newWrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.hidden {
  display: none;
}

.headingResponsive {
  width: 740px;
}

.footerResponsive {
  width: 740px;
}

.disabledColor {
  background-color: var(--gray1);
}

.hideContent {
  display: none;
}

.downloadLogoShow {
  position: absolute;
  left: 50%;
  bottom: 10%;
  transform: translate(-50%, 0);
  width: 64%;
  height: 77%;
  opacity: 0.1;
}

.consolidationField {
  display: flex;
  justify-content: flex-start;
  max-width: 88rem;
  position: relative;
}

.downloadDisabledColor {
  background-color: unset;
}

@media print {
  .hidden {
    display: unset;
  }

  .circleClose {
    display: none;
  }

  .mainContainer {
    min-height: 1123px;
  }

  .disabledColor {
    background-color: unset;
  }

  .cargoLogo {
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translate(-50%, 0);
    width: 64%;
    height: 77%;
    opacity: 0.1;
  }

  .closeButton {
    display: none;
  }
}
