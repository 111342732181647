@import '../../../../css/main.css';
@import '../../../../css/colors.css';
@import '../../../../css/variables.css';

.row {
  display: flex;
  position: relative;
}

.textareaField {
  flex: 1;
  background-color: var(--white);
  border: 1rem solid var(--borderFormElement);
  border-radius: var(--borderRadius);
  padding: 10rem;
  outline: none;
  transition: all 0.2s;
  resize: none;
}

.textareaField::placeholder {
  color: var(--placeholderColor);
}

.textareaField:hover {
  border-color: var(--borderFormElementHover);
}

.textareaField:focus {
  border-color: var(--borderFormElementFocus);
}

.textareaDiv {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.textareaField:disabled {
  background-color: var(--disabledBackgroundColor);
}

.textareaLabel {
  color: var(--secondaryText);
  font-size: 12rem;
  font-weight: 400;
  margin: 0 0 5rem 0;
  user-select: none;
  transition: color 0.4s;
}

.textareaDiv:focus-within .textareaLabel {
  color: var(--colorFormElementFocus);
}
