@import '../../../../../css/colors.css';
@import '../../../../../css/variables.css';

.agreementContainer {
  display: flex;
  flex: 1;
  width: calc(210mm + var(--elementMinWidth));
  max-width: 100%;
  flex-wrap: wrap;
}

.agreementWrapper {
  display: flex;
}

.agreementsListWrapper {
  display: flex;
}

.documentsAndControls {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: var(--elementMinWidth);
  padding: var(--elementPadding);
  padding-bottom: 0;
  background-color: var(--background);
}

.cmrWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  width: calc(var(--elementMinWidth) * 3 + 24px);
  overflow-x: auto;
}

.documentControls {
  display: flex;
  padding: 20px 20px 0 20px;
  justify-content: center;
  align-items: center;
  background-color: var(--background);
  min-width: var(--elementMinWidth);
}

.responsiveTest {
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: var(--gap10);
  width: 100%;
  background-color: var(--background);
  padding: var(--elementPadding);
}

.cmrWrapperResponsive {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.customHeightClass {
  height: 780px;
}

.hidden {
  display: none;
}

.flexClass {
  width: 100%;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.element {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 10px 0;
  text-align: center;
}

.element:hover {
  cursor: pointer;
  background-color: var(--gray1);
}

.transportServiceAgreement {
  width: fit-content;
  cursor: pointer;
  text-decoration: underline;
}

.actionInfo {
  display: flex;
  flex: 1;
  min-width: var(--elementMinWidth);
  padding: var(--elementPadding) var(--elementPadding) 0 var(--elementPadding);
}

.resizeDrawer {
  width: var(--elementMinWidth);
  min-width: var(--elementMinWidth);
  transition: all var(--formDrawerAnimationTime) ease;
}

@media print {
  ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: transparent;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: transparent;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: transparent;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: transparent;
  }

  .hidden {
    display: unset;
  }

  .printPage {
    /* width: 794px; */
    /* overflow: unset; */
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
