:root {
  /* ---------- Colors ---------- */
  /* after changing colors update PaymentCommon.js */

  /* Light */
  --darkPrimaryLinearGradient: linear-gradient(90deg, rgba(0, 91, 158, 1) 0%, rgba(0, 125, 193, 1) 35%);
  --primaryDark: #005b9e;
  --primaryDarkHover: #196ba8;
  --primaryDarkClicked: #337cb1;
  --logoBlue: #005b9e;
  --primary: #2586c5;
  --primaryHover: #007dc1;
  --secondary: #0c2138;
  --secondaryHover: #5a6268;
  --hoverLightBlue: #d9e7f1;
  --tertiary: #ebd671;
  --tertiaryDark: #ffc60a;
  --tertiaryHover: #ece3b8;
  --tertiaryLight: #ffc60a9e;
  --success: #67b164;
  --successLight: #67b16426;
  --successHover: #579955;
  --warning: var(--tertiary);
  --warningHover: #ebb70e;
  --danger: #f78079;
  --dangerHover: #e75951;
  --inactive: var(--gray3);
  --white: #ffffff;
  --background: #f1f4f6;
  --selectedMenuItemBackground: rgba(37, 134, 197, 0.15);
  --opaqueBackground: rgb(241 244 246 / 0.8);
  --primaryText: var(--secondary);
  --secondaryText: #8f99a2;

  /* Grays */
  --gray1: #e4e6e8;
  --gray2: #c9cdd2;
  --gray3: #8f99a2;
  --gray4: #788390;
  --gray5: #5d6b7a;
  --gray6: #425264;

  /* green */
  --green: #67b164;

  --info: #30b1ff;

  /* Order status colors */
  --statusPending: #425264;
  --statusLoading: #ebb70e;
  --statusInTransit: #005b9e;
  --statusAtCustoms: #aa51d4;
  --statusUnloading: #f07b0f;
  --statusCompleted: #579955;
  --statusCancelled: #e75951;

  --primarySvg: var(--primary);
  --primaryDarkSvg: var(--primaryDark);
  --secondarySvg: var(--secondary);
  --tertiarySvg: var(--gray3);
  --whiteSvg: var(--white);

  --borderFormElement: var(--gray1);
  --borderFormElementHover: var(--gray4);
  --borderFormElementFocus: var(--primary);
  --colorFormElementFocus: var(--gray5);
  --placeholderColor: var(--gray2);
  --disabledBackgroundColor: var(--gray1);
}

svg {
  fill: var(--primarySvg);
  /* stroke: var(--secondarySvg); */
  height: 25rem;
  width: 25rem;
}
