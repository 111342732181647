.mainDiv {
  background-image: url('../../../assets/images/png/blurBg.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  filter: blur(20px);
}

.modal {
  z-index: 100;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500rem;
  /* height: 200rem; */
  border-radius: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--white);
  padding: 20rem;
  gap: 10px;
  /* box-shadow: var(--cardBoxShadow); */
}

.modal h1 {
  text-align: center;
}

.report {
  display: flex;
  flex-direction: column;
  gap: var(--gap10);
}

.fieldDiv {
  position: relative;
}

.copiedText {
  color: var(--white);
  font-weight: 500;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 4rem;
  background-color: var(--success);
  border-radius: 3rem;
  animation: slideAndFade 1s;
}

.idInput {
  display: flex;
  height: 35px;
  padding: 10px;
  border-radius: 4px;
  background-color: var(--gray1);
  cursor: pointer;
}

.textarea {
  height: 100px;
}

.sendReport {
  margin-top: 40px;
}

.title {
  margin-bottom: 40px;
}

.errId {
  color: var(--gray3);
}

@media screen and (max-width: 516px) {
  .modal {
    width: 90%;
  }
}
