.wrapper {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  gap: 20rem;
  max-width: 730px;
  /* padding: 20rem; */
  background-color: var(--white);
  bottom: 0;
  right: 0;
  z-index: 3;
  box-shadow: 0px 2px 8px rgb(12 33 56 / 50%);
  justify-content: center;
}
.container {
  display: flex;
  flex: 1;
  max-width: 1500rem;
  align-self: center;
  padding: 20rem 40rem;
  gap: 20rem;
  align-items: center;
  flex-wrap: wrap;
}

.header {
  background-color: var(--primary);
  color: var(--white);
  width: 100%;
  height: 40rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10rem 15rem;
  border-radius: 4rem 4rem 0 0;
}

.headerRes {
  border-radius: 0rem;
}

.closeWrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.closeWrapperResponsive {
  position: absolute;
  top: 10rem;
  right: 40rem;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 95%;
  padding: 20rem;
  gap: 20rem;
}

.sectionWrapper {
  display: flex;
  align-items: center;
  gap: 10rem;
  flex-wrap: wrap;
  flex: 1;
  flex-basis: 590px;
  max-width: 100%;
  width: 100%;
  min-width: 363px;
  flex-wrap: wrap-reverse !important;
}
.sectionWrapper button:nth-child(3) {
  width: 100%;
  min-width: 245px;
  max-width: 100%;
  flex: 1;
}

.sectionWrapper button {
  width: 100%;
  min-width: 230px;
  max-width: 100%;
  flex: 1;
  /* background-color: var(--white); */
}

.sectionWrapper button:nth-child(2) {
  background-color: inherit;
  color: var(--primary);
  border: 1rem solid var(--primary);
}

.buttonsResponsive {
  /* flex-direction: column; */
  width: 100%;
  min-width: fit-content;
}

.button {
  width: 100%;
}

.textWrapper {
  font-size: 12rem;
  flex: 1000 1;
  flex-basis: 600rem;
  min-width: 300px;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  gap: 20rem;
  flex-wrap: wrap;
}

.buttons {
  flex-grow: 1;
}

.svg {
  fill: var(--white);
  cursor: pointer;
}

.settingsModal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  box-shadow: var(--cardBoxShadow);
  width: 600rem;
  height: 700rem;
  border-radius: 0 0 4rem 4rem;
}

.modalResponsiveTablet {
  width: 400rem;
}

.modalResponsive {
  height: 100%;
  width: 100%;
  z-index: 1;
}

.contentWrapperResponsive {
  height: 97%;
}

.registerForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  height: 100%;
  gap: 40rem;
}

.registerFormResponsive {
  height: 100%;
  width: 100%;
}

.formHeader {
  font-size: 14rem;
  font-weight: 500;
  color: var(--secondary);
}

.formInputs {
  display: flex;
  flex-direction: column;
  gap: 10rem;
  flex: 1000 1;
  overflow-y: scroll;
  width: 100%;
  height: 300rem;
}

.modalButtons {
  width: 100%;
  display: flex;
  gap: 10rem;
}

.logo {
  width: 102rem;
  height: 38rem;
  /* margin-bottom: 60rem; */
}

.subInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 10rem;
  padding-top: 10rem;
}

.cookiesUsedWrapper {
  display: flex;
  flex-direction: column;
  gap: 6rem;
}

.usedCookiesHeader {
  width: 100%;
  font-size: 10rem;
  color: var(--gray5);
  margin-bottom: 6;
  border-bottom: 1px solid var(--gray2);
}

.cookiesArray {
  width: 100%;
  font-size: 10rem;
  color: var(--gray5);
}

/* Not modal */

.cookiePolicyWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 330rem;
  max-width: 100%;
}

.cookiePolicyWrapperRes {
  width: 100%;
}

.contentWrapperDrawer {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  padding: 15rem;
  gap: 20rem;
}

.actionInfo {
  width: 330rem;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20rem;
  justify-content: space-between;
}

.headingLink {
  cursor: pointer;
}

.link {
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
}
