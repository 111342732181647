.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  border-radius: 2px;
  opacity: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 0 0 10px;
  background-color: #f1f4f6;
  color: var(--secondaryText);
  height: 40rem;
}

.header svg {
  margin-right: 10px;
}

.content {
  overflow: hidden;
  transition: height 0.2s ease-in-out;
  padding: 0 10px;
}

.headerWrapper {
  width: 100%;
  height: 40rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: 15rem;
  color: var(--secondary);
  width: 100%;
}

.activeHeader {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 15rem;
  color: var(--secondary);
  width: 100%;
}

.contentWrapper {
  width: 400rem;
  display: flex;
  align-items: center;
  gap: 15rem;
}

.toggleWrapper {
  z-index: 3000;
  height: 40rem;
  display: flex;
  align-items: center;
}

.activeText {
  margin-right: 10rem;
  color: var(--primary);
  font-size: 10rem;
  width: 100%;
  text-align: right;
}

.arrowDown {
  width: 16rem;
  height: 16rem;
  margin: 20px 20px 20px 5px;
  stroke: var(--secondaryText);
}

.arrowUp {
  transform: rotate(180deg);
  width: 16rem;
  height: 16rem;
  margin: 20px 20px 20px 5px;
  stroke: var(--secondaryText);
}
