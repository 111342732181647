.container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: auto;
}

.errorItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5rem;
}

.errorLabel {
  font-size: 16rem;
  font-weight: 600;
}

.errorExclamationMark {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--white);
  background-color: var(--danger);
  border-radius: var(--borderRadius);
  min-width: 25px;
  width: 25px;
  height: 25px;
  user-select: none;
  cursor: pointer;
  margin-right: 5rem;
}

.errorText {
  color: var(--danger);
  font-size: 12rem;
  font-weight: 400;
}

.globalErrorsContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-left: 1rem solid var(--danger);
  border-radius: 5rem;
  border: 1rem solid var(--danger);
  border-left-width: 5rem;
  padding: 10rem 20rem 15rem;
}
