@import '../../../css/main.css';
@import '../../../css/colors.css';

.ldsRing {
  display: flex;
  position: absolute;
  top: 0rem;
  left: 0rem;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: var(--opaqueBackground);
  z-index: 9999;
  animation-name: fade-in-custom;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
}

@keyframes fade-in-custom {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.logo {
  width: 100rem;
  height: 100rem;
}

.ldsRingCard {
  display: flex;
  position: absolute;
  top: 0rem;
  left: 0rem;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: var(--opaqueBackground);
  z-index: 9999;
  animation-name: fade-in-custom;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
}

@keyframes fade-in-custom {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.logoCard {
  width: 50rem;
  height: 50rem;
}

.componentLoader {
  z-index: 100;
}
