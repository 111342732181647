.tooltip {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  border-radius: 6px !important;
  box-shadow: 0px 1px 8px #00000029;
  background-color: white !important;
  color: var(--secondary) !important;
  font-size: 12px !important;
  font-weight: 500;
  opacity: 0 !important;
  transition:
    visibility 0s linear 0.3s,
    opacity 0.3s !important;
  margin-top: 0 !important;
  max-width: 85%;
  z-index: 9999;
}

.tooltip div {
  flex-wrap: wrap;
  white-space: wrap;
  display: flex;
  word-break: normal;
  z-index: 9999;
}

.tooltip[class*='show'] {
  opacity: 1 !important;
  transition:
    visibility 0s linear 0s,
    opacity 0.3s !important;
}

.tooltip::after {
  display: none;
}
