.buttonWrapper {
  display: flex;
  position: relative;
  width: 100%;
}

.buttonStyle {
  position: relative;
  width: 100%;
}

.arrowUp {
  transform: rotate(180deg);
  width: 16rem;
  height: 16rem;
  stroke: var(--gray3);
}

.arrowDown {
  width: 16rem;
  height: 16rem;
  stroke: var(--gray3);
}

.arrowUpResponsive {
  transform: rotate(180deg);
  width: 16rem;
  height: 16rem;
  stroke: var(--gray3);
}

.arrowDownResponsive {
  width: 16rem;
  height: 16rem;
  stroke: var(--gray3);
}

.dropdownMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  background-color: var(--white);
  user-select: none;
  top: 0;
  z-index: 999;
}

.dropdownMenuResponsive {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  background-color: var(--white);
  user-select: none;
  bottom: 35px;
  z-index: 999;
}

.arrowContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 20px;
  position: absolute;
  right: 4px;
  top: 10px;
  z-index: 998;
}

.arrowContainer:hover {
  cursor: pointer;
}
