@import '../../../css/colors.css';
@import '../../../css/variables.css';

.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.modal:focus {
  outline: none;
}

.modalResponsive {
  width: 100%;
  height: 100%;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 9999;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: var(--cardBorderRadius);
  box-shadow: var(--cardBoxShadow);
  height: fit-content;
  width: min(var(--elementMinWidth), 100vw);
}

.modalContainerMobile {
  height: 100%;
  width: 100%;
  border-radius: 0%;
}

.modalTitle {
  display: flex;
  justify-content: center;
  font-size: 16rem;
  font-weight: 400;
  padding: 10rem 20rem;
  user-select: none;
  overflow: hidden;
}

.modalTitleMobile {
  padding: var(--elementPadding);
  min-height: var(--headerHeight);
}

.primary {
  background-color: var(--primary);
  color: var(--white);
}

.tertiary {
  background-color: var(--tertiary);
  color: var(--secondary);
}

.success {
  background-color: var(--success);
  color: var(--white);
}

.danger {
  background-color: var(--danger);
  color: var(--white);
}

.tertiary {
  background-color: var(--tertiary);
  color: var(--secondary);
}

.modalMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100rem;
  font-size: 15rem;
  font-weight: 400;
  color: var(--secondary);
  padding: var(--elementPadding);
  user-select: none;
}

.modalButtons,
.modalButtonsReversed {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: var(--elementPadding) calc(var(--elementPadding) * 2);
  gap: var(--gap20);
}
.modalButtons {
  flex-direction: row;
}
.modalButtonsReversed {
  flex-direction: row-reverse;
}

.buttonControlsMobile {
  margin-top: auto;
}

.modalButtonBlue {
  width: 100%;
}
.modalButtonRed {
  width: 100%;
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--gap10);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32rem;
  align-items: center;
  padding: 70rem 0;
  overflow: auto;
}

.message {
  font-size: 15rem;
  font-weight: 400;
  color: var(--secondary);
  padding: 10rem;
  user-select: none;
}

.logo {
  height: 113rem;
  width: 100%;
}

.logoAnimated {
  height: 98rem;
  width: 100%;
}
