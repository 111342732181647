.footer {
  display: flex;
  flex-direction: column;
  gap: var(--elementPadding);
  margin-top: auto;
  position: sticky;
  bottom: 0px;
  padding: var(--elementPadding) 10rem;
  background-color: inherit;
  z-index: 2;
  background-color: var(--white);
  border-radius: 6rem;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: var(--gap10);
}
