.newVersion {
  display: flex;
  flex-direction: column;
  gap: var(--gap20);
  width: 100%;
}

.version {
  font-weight: 500;
}

.newVersionModal {
  height: unset !important;
  padding: 20px 60px !important;
  width: 100%;
}

.patchNotes {
  width: 100%;
  display: inline-block;
  max-height: 300px;
  overflow: auto;
  text-align: left;
  word-break: break-word;
  word-wrap: break-word;
  white-space: pre-line;
  line-height: 25px;
}

.badRequestMessage {
  line-height: 30px;
  white-space: pre-line;
  text-align: left !important;
  height: fit-content !important;
}

.cookieSettings {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10rem 25rem 10rem 10rem;
  display: flex;
  gap: 10rem;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  border: 1px solid #e7e9eb;
  border-radius: 4rem 0 0 0;
  font-size: 14rem;
  cursor: pointer;
  background-color: var(--white);
}

.svg {
  fill: var(--white);
}

.cookieForm {
  height: 100% !important;
}

*[name='loc-highlight'] {
  color: var(--secondary);
  font-weight: 500;
}
