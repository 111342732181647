.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: inherit;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.circle {
  margin-top: 30%;
  width: 115px;
  height: 115px;
}

.message {
  margin: 50px 0px;
  display: flex;
  flex-direction: column;
  gap: var(--gap10);
  width: 100%;
}

.message p {
  text-align: center;
  margin: auto;
  width: 90%;
  font-size: 16rem;
  line-height: 30rem;
  color: #6a7285;
}

.successMessage {
  color: var(--success);
}

.tertiaryMessage {
  color: var(--tertiary);
}

.errorMessage {
  color: var(--danger);
}

.infoMessage {
  color: var(--primary);
}

.infoMessage,
.successMessage,
.errorMessage {
  font-size: 16rem;
}

.button {
  width: 35%;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap10);
  margin-top: auto;
  position: sticky;
  bottom: 0px;
  width: 100%;
  padding: 20px 0;
  background-color: inherit;
}

.controls button {
  width: 100%;
  max-width: 350px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32rem;
  padding: 12rem 0;
}

.imagecontentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30rem;
}

.textWidth {
  width: 80%;
}

.footerText {
  color: var(--primary);
  font-size: 12rem;
}

.sectionWrappSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title {
  font-size: 24rem;
}

.actionMessage {
  font-size: 16rem;
  letter-spacing: 0px;
}

.logoSuccess {
  width: 96px;
  height: 96px;
}

.logoSuccessContainer {
  width: 100rem;
  height: 100rem;
}

.imageClass {
  width: unset;
  height: unset;
}

*[name='highlight'] {
  font-weight: 700;
}

.background {
  background-color: white;
}
