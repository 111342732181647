@import '../../../css/colors.css';
@import '../../../css/variables.css';

.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.modal:focus {
  outline: none;
}

.modalResponsive {
  height: 100%;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 9999;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: var(--cardBorderRadius);
  box-shadow: var(--cardBoxShadow);
  height: fit-content;
  width: min(500px, 100vw);
  overflow: hidden;
}

.modalContainerMobile {
  height: 100%;
  border-radius: 0%;
}

.modalTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  font-size: 16rem;
  font-weight: 400;
  padding: 10rem 20rem 10rem 20rem;
  min-height: 30rem;
  user-select: none;
  overflow: hidden;
}

.closeSvg {
  position: absolute;
  fill: var(--white);
  right: 13rem;
  width: 18rem;
  height: 18rem;
  cursor: pointer;
}

.primary {
  background-color: var(--primary);
  color: var(--white);
}

.tertiary {
  background-color: var(--tertiary);
  color: var(--secondary);
}

.success {
  background-color: var(--success);
  color: var(--white);
}

.danger {
  background-color: var(--danger);
  color: var(--white);
}

.tertiary {
  background-color: var(--tertiary);
  color: var(--secondary);
}

.modalMessage {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  /* min-height: 100rem; */
  font-size: 15rem;
  font-weight: 400;
  color: var(--secondary);
  padding: 20rem;
  user-select: none;
}

.secondModalMessage {
  padding: 0 20px;
}

.modalButtons,
.modalButtonsReversed {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 20rem;
  gap: var(--gap20);
}
.modalButtons {
  flex-direction: row;
}
.modalButtonsReversed {
  flex-direction: row-reverse;
}

.buttonControlsMobile {
  margin-top: auto;
}

.modalButtonBlue {
  width: 100%;
}
.modalButtonRed {
  width: 100%;
}

.buttonsColumnClass {
  display: flex;
  flex-direction: column;
  gap: var(--gap10);
}

.additionalContent {
  display: flex;
  justify-content: center;
  padding: 0 0 20rem 0;
}

.topContent {
  display: flex;
  flex: 1;
  padding: var(--elementPadding);
}
