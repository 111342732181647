.wrapper {
  display: flex;
  align-items: baseline;
}

.hideChecker {
  visibility: hidden;
}

.dot {
  height: 8rem;
  width: 8rem;
  margin-right: 5rem;
  background-color: red;
  border-radius: 50%;
}

.mediumDot {
  background-color: orange;
}

.strongDot {
  background-color: green;
}
