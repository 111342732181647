@import '../../../css/colors.css';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  padding: 25rem;
  background-color: var(--background);
  overflow-y: scroll;
}

.unauthenticatedContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80rem 20rem 100rem 20rem;
  background-color: var(--background);
  flex: 1;
}

.notFoundContainer {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.notFound {
  width: 600rem;
  min-width: 600rem;
  min-height: 300rem;
  height: 300rem;
  user-select: none;
}

.buttonContainer {
  display: flex;
  align-self: flex-start;
}

.button {
  align-self: flex-start;
  margin-bottom: 50rem;
}

@media only screen and (max-width: 929px) {
  .notFound {
    width: 500rem;
    min-width: 500rem;
    min-height: 250rem;
    height: 250rem;
    user-select: none;
  }
}

@media only screen and (max-width: 829px) {
  .notFound {
    width: 400rem;
    min-width: 400rem;
    user-select: none;
  }
}

@media only screen and (max-width: 475px) {
  .notFound {
    display: none;
  }
}
