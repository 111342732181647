@import '../../../css/variables.css';

.drawerWrapper {
  display: flex;
  position: fixed;
  top: 0rem;
  left: 0rem;
  height: 100%;
  width: 100%;
  padding: var(--formDrawerWrapperGap) 0rem;
  background-color: #0c213841;
  filter: drop-shadow(0px 0px 0px rgb(43, 42, 42));
  z-index: 999;
  transition: all var(--formDrawerAnimationTime) ease;
  justify-content: flex-end;
}

.drawerWrapperResponsive {
  padding: 0rem;
}

.drawer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: min-content;
  right: 0rem;
  max-width: 100%;
  height: 100%;
  /* align-self: center; */
  border-radius: 10rem 0rem 0rem 10rem;
  background-color: var(--white);
  animation-name: slideIn;
  animation-fill-mode: forwards;
  animation-duration: var(--formDrawerAnimationTime);
  animation-iteration-count: 1;
  overflow: auto;
  /* overflow-y: overlay;
  overflow-x: hidden; */
}

.drawerResponsive {
  border-radius: unset;
}

@keyframes slideIn {
  from {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOut {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(100%, 0, 0);
  }
}

.hide {
  animation-name: slideOut;
}

.drawerHeader {
  display: flex;
  flex-direction: row;
  position: sticky;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: 100%;
  padding: 9px 20px;
  background-color: var(--white);
  z-index: 999;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
}

.formTitle {
  display: flex;
  font-size: 14px;
  line-height: 22px;
  user-select: none;
}

.drawerHeader.gray {
  background-color: var(--gray1);
  color: var(--white);
  background: linear-gradient(48deg, rgba(201, 205, 210, 1) 0%, rgba(228, 230, 232, 1) 100%);
  -webkit-box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.16);
  box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.16);
}

.drawerHeader.danger {
  color: var(--white);
  background-color: var(--danger);
  background: linear-gradient(48deg, rgba(231, 89, 81, 1) 0%, rgba(247, 128, 121, 1) 100%);
  -webkit-box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.6);
  box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.6);
}

.drawerHeader.success {
  color: var(--white);
  background-color: var(--success);
  background: linear-gradient(48deg, rgba(87, 153, 85, 1) 0%, rgba(103, 177, 100, 1) 100%);
  -webkit-box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.4);
}

.drawerHeader.primary {
  color: var(--white);
  background-color: var(--primary);
  background: linear-gradient(48deg, rgba(0, 91, 158, 1) 0%, rgba(0, 125, 193, 1) 100%);
  -webkit-box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.65);
  box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.65);
}

.drawerHeader.tertiary {
  color: var(--white);
  background-color: var(--tertiaryHover);
  background: linear-gradient(48deg, var(--tertiary) 0%, var(--tertiaryHover) 100%);
  -webkit-box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.2);
}

.closeSvg {
  display: flex;
  width: 18rem;
  height: 18rem;

  fill: var(--white);
  cursor: pointer;
  transition: fill 0.3s;
}

.closeSvgWhite {
  fill: var(--white);
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.headerless {
  flex-direction: column;
  background-color: transparent;
}
.chatWrapper {
  width: 100%;
  max-width: 100%;
}
