.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modalResponsive {
  position: unset;
  left: unset;
  top: unset;
  transform: unset;
  display: flex;
  height: 100%;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(12, 33, 56, 0.45);
  background-color: -moz-rgba(12, 33, 56, 0.45);
  background-color: -webkit-rgba(12, 33, 56, 0.45);
  z-index: 1000;
}

.logoAndDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20rem;
}
.logInButtonColor {
  background-color: var(--logoBlue);
}
.modalDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
}

.modalDescriptionMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10rem;
}

.forgotPassLink {
  display: flex;
  flex-direction: row-reverse;
}
.forgotPassLink > a {
  text-decoration: none;
  color: var(--primary);
  font-weight: 500;
  font-size: 14px;
}
.messageAndDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal:focus {
  outline: none;
}

.expiredMobile {
  /* position: relative; */
  padding: 0;
  margin-top: 0;
}

.expired {
  position: relative;
  padding: 0;
  margin-top: 16rem;
}
.logo {
  width: 100rem;
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 397px;
  min-height: 432px;
  background-color: var(--white);
  border-radius: 12px;
  padding: 20rem;
  position: relative;
  gap: 44rem;
}
.formContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 20rem;
}

.formContentWrapperMobile {
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20rem;
}

.wrapperResponsive {
  border-radius: unset;
  overflow: auto;
  height: 100%;
  width: 100%;
  padding: 0rem 15rem 40rem 15rem;
}

.title {
  color: var(--primary);
  font-weight: 600;
  font-size: 22px;
}
.description {
  font-weight: 400;
  font-size: 16px;
  white-space: normal;
  text-align: center;
}

.descriptionMobile {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  white-space: normal;
  text-align: center;
}

.message {
  font-weight: 400;
  font-size: 16px;
  white-space: pre-line;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  stroke: var(--gray3);
}
