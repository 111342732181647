:root {
  /* after changing variables update PaymentCommon.js */
  --headerHeight: 50px;
  --cardHeaderHeight: 34px;

  --borderRadius: 6px;
  --dashboardPanelBorderRadius: 8px;
  --cardBorderRadius: 8px;

  --cardBoxShadow: 0px 2px 8px rgb(12 33 56 / 20%);
  --cardBoxShdowHover: 0px 2px 16px rgb(12 33 56 / 20%);
  --cardBoxShadowTransition: box-shadow 0.2s;

  --navMenuMinWidthOpened: 210rem; /* Also change in useWindowDimensions.js!!! */
  --navMenuMinWidthClosed: 52rem; /* Also change in useWindowDimensions.js!!! */
  --cardMinWidth: 287px; /* Also change in useWindowDimensions.js!!! */
  --elementMinWidth: 320px; /* Also change in useWindowDimensions.js!!! */
  --elementPadding: 15px; /* Also change in useWindowDimensions.js!!! */

  --scrollWidth: 6px; /* Also change in useWindowDimensions.js!!! */
  --gap10: 10px;
  --gap20: 20px;
  --gap30: 30px;
  --gap5: 5px;
  --gap15: 15px;

  --formDrawerHeaderHeight: 40px;
  --formDrawerWrapperGap: 10px;
  --formDrawerAnimationTime: 0.4s;
  --formDrawerContentHeightPC: calc(100vh - var(--formDrawerHeaderHeight) - 2 * var(--formDrawerWrapperGap));
  --formDrawerContentHeightNotPC: calc(100vh - var(--formDrawerHeaderHeight));
}

/* Also change in useWindowDimensions.js!!! */
@media only screen and (min-width: 1441px) {
  :root {
    --elementMinWidth: 360rem; /* Also change in useWindowDimensions.js!!! */
  }
}

@media only screen and (max-width: 350px) {
  :root {
    --elementMinWidth: 284rem; /* Also change in useWindowDimensions.js!!! */
  }
}
