:root {
  font-size: 1px;
}

/* contain overscroll behaviour only in vertical direction */
body {
  overscroll-behavior-y: none;
  overflow: hidden;
}

* {
  box-sizing: border-box;
  touch-action: pan-x pan-y;
  line-height: 1.2;
}

button {
  outline: none;
}

input,
textarea,
label {
  font-size: 12px;
}

input,
textarea,
select,
button {
  font-family: inherit;
}

sup {
  font-size: 8px;
}

/* disables autofill blue background (on chrome) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

::-webkit-scrollbar {
  width: var(--scrollWidth);
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--gray3);
  border-radius: 6rem;
}
