@import '../../../css/colors.css';
@import '../../../css/variables.css';

.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.modal:focus {
  outline: none;
}

.modalResponsive {
  height: 100%;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 9999;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: var(--cardBorderRadius);
  box-shadow: var(--cardBoxShadow);
  height: fit-content;
  min-height: 300rem;
  justify-content: center;
  width: min(500px, 100vw);
  overflow: hidden;
}

.modalContainerMobile {
  height: 100%;
}

.modalTitle {
  display: flex;
  justify-content: center;
  font-size: 16rem;
  font-weight: 400;
  padding: 10rem 20rem 10rem 20rem;
  user-select: none;
  overflow: hidden;
}

.modalTitleMobile {
  padding: 20px;
}

.primary {
  background-color: var(--primary);
  color: var(--white);
}

.tertiary {
  background-color: var(--tertiary);
  color: var(--secondary);
}

.success {
  background-color: var(--success);
  color: var(--white);
}

.danger {
  background-color: var(--danger);
  color: var(--white);
}

.modalMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  /* height: 100rem; */
  font-size: 15rem;
  font-weight: 400;
  color: var(--secondary);
  padding: 20rem 20rem;
  user-select: none;
}

.modalButtons,
.modalButtonsReversed {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 20rem 20rem 20rem 20rem;
  gap: var(--gap20);
}
.modalButtons {
  flex-direction: row;
}
.modalButtonsReversed {
  flex-direction: row-reverse;
}

.modalButtonBlue {
  width: 100%;
}
.modalButtonRed {
  width: 100%;
}

.modalControlsMobile {
  margin-top: auto;
}

.formFields {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 20rem;
  user-select: none;
  gap: var(--gap10);
}

.textarea {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  height: 80rem;
  min-height: 80rem;
}

.copiedText {
  color: var(--white);
  font-weight: 500;
  position: absolute;
  top: 145rem;
  left: 420rem;
  padding: 4rem;
  background-color: var(--success);
  border-radius: 3rem;
  animation: slideAndFade 1s;
}
@keyframes slideAndFade {
  0%,
  100% {
    opacity: 0;
    top: 145rem;
  }
  50% {
    top: 140rem;
    opacity: 1;
  }
}
