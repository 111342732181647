.wrapper {
  display: flex;
  width: 720px;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding: 40px;
  gap: 20px;
}

.paddingMobile {
  padding: 10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.headingData {
  font-size: 12px;
  font-weight: 600;
  align-self: center;
  text-align: center;
}

.logo {
  width: 90px;
  height: 31px;
  position: absolute;
  left: 0;
}

.listItemsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--gap20);
}

.wrapper ol,
.wrapper ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-inline-start: 20px;
  gap: 20px;
}

.buttonControls {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--elementPadding);
  width: 100%;
  gap: var(--gap10);
}

.buttonControls button {
  width: 50%;
}

.responsiveWidth {
  width: 100vw;
}
