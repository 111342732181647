.container {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  width: calc(var(--elementMinWidth));
}

.containerMobile {
  flex-direction: column;
  width: 100vw;
}

.infoPanelWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: var(--formDrawerContentHeightPC);
  max-height: var(--formDrawerContentHeightPC);
}

.infoPanelWrapperSticky {
  position: sticky;
  top: var(--formDrawerHeaderHeight);
}

.dataInfoWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--gap15);
  padding: var(--elementPadding);
  padding-bottom: 0;
  background-color: var(--white);
  height: var(--formDrawerContentHeightPC);
  max-height: var(--formDrawerContentHeightPC);
}

.dataInfoWrapperScroll {
  overflow-y: scroll;
}

.version {
  font-weight: bold;
  margin-top: calc(-2 * var(--elementPadding));
}

.mainWrapper {
  width: 100%;
  max-width: 100%;
}

.featureTitle {
  color: var(--primary);
  font-size: 14rem;
  /* text-transform: capitalize; */
  margin-bottom: var(--elementPadding);
}

.imageWrapper {
  width: 100%;
}

.image {
  width: 100%;
  margin-bottom: var(--elementPadding);
}

.textWrapper {
  width: 100%;
  color: var(--secondaryText);
  overflow-x: hidden;

  white-space: pre-line;
  word-wrap: break-all;
  word-break: break-word;
}
.wide {
  width: 100%;
}
