.errorExclamationMark {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: var(--white);
  background-color: var(--danger);
  border-radius: var(--borderRadius);
  min-width: 30px;
  width: 30px;
  height: 30px;
  user-select: none;
  cursor: pointer;
  margin-left: 10px;
}

.errorMessage {
  display: flex;
  align-items: center;
  width: calc(100% - 40px);
  min-height: 100%;
  border-radius: var(--borderRadius);
  padding: 6px 8px;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  color: var(--white);
  background-color: var(--danger);
  z-index: 1;
  transition: opacity 0.3s;
  font-size: 12rem;
}

.errorExclamationMark:hover ~ .errorMessage {
  visibility: visible;
  opacity: 1;
}

.inlineContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.inlineContainer p {
  color: var(--danger);
}

.bigLabel {
  font-size: 14rem;
}

.bigExclamationMark {
  height: 35rem;
  width: 35rem;
}
