.mainWrapper {
  width: var(--elementMinWidth);
  padding: var(--elementPadding);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.wrapperResponsive {
  width: 100vw;
}

.info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: var(--elementPadding);
  font-size: 14rem;
}

.resWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
