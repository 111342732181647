@import '../../../css/main.css';
@import '../../../css/colors.css';

.ldsRing {
  display: flex;
  flex-grow: 10;
  margin: auto;
  justify-content: center;
  position: relative;
}

.ldsRing div {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  border: solid;
  border-radius: 50%;
  animation: ldsRing 1.5s linear infinite;
  border-color: var(--secondarySvg) var(--white) var(--white) var(--white);
}

@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
