.checkBox {
  display: flex;
  margin-right: 5rem;
  align-items: center;
  margin-left: 15px;
}

.input {
  position: relative;
  appearance: none;
  width: 41px;
  height: 20px;
  border-radius: 82px;
  background: var(--gray2);
  cursor: pointer;
  transition: 0.4s;
}

.input:checked[type='checkbox'] {
  background: var(--primary);
}

.input::after {
  position: absolute;
  content: '';
  width: 16.4px;
  height: 16px;
  top: 2px;
  left: 2.05px;
  background: var(--white);
  border-radius: 50%;
  transform: scale(1.1);
  transition: ease-in-out 0.4s;
}

.input:checked[type='checkbox']::after {
  left: 55%;
  background: var(--white);
}

.disabled {
  pointer-events: none;
}
